import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
require 'trix'
# require '@rails/actiontext'

import UiKit from 'uikit'
import UiIcons from 'uikit/dist/js/uikit-icons.min'

import 'controllers'

UiKit.use(UiIcons)

window.Swal = require 'sweetalert2/dist/sweetalert2.min'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
