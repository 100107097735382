import { Controller } from "stimulus"

export default class extends Controller
  setVictims: (eve) ->
    @type = @.data.get 'type'
    @victim_show = @.data.get 'victim'
    if (@type == eve.target.value)
      document.getElementById(@victim_show).classList.remove("uk-hidden")
    else
      document.getElementById(@victim_show).classList.add("uk-hidden")
