import { Controller } from 'stimulus'

export default class extends Controller
  formSend: (eve) ->
    setTimeout(
      ->
        button = document.getElementById('the-submitter')
        button.disabled = false
        console.log 'eve', eve
        eve.target.reset()
      1000
    )
