import { Controller } from "stimulus"
import fetchOptions from '../utils/fetch-options'

export default class extends Controller
  @targets: ['master']

  setVictims: () ->
    { data } = this
    pairs = JSON.parse data.get('pairs')
    for pair in pairs
      victims = pair.victims.split ','
      url = pair.url
      final_data = if pair.data then pair.data else {}
      id = @masterTarget.value
      fetchOptions "#{url}/#{id}.json", { params: final_data }, victims

  connect: () ->
    checker = parseInt @masterTarget.value
    @setVictims() if checker
